<template>
  <div>  
    <CRow>
      <CCol lg="12">
        <CButton color="info" size="sm" @click="link('home')" style="float: right;">Inicio</CButton>
        <h2>INFORMACIÓN DE LA RUTA REZAGADA</h2>
      </CCol>
    </CRow> 
    <CRow >
      <CCol :md="12" >
        <CCard >
          <CCardBody>
            <CRow >
              <CCol :md="2">
                <strong>Nombre:</strong>
                <br />
                <strong>Promotor:</strong>
              </CCol>
              <CCol :md="3">
                <span style="float: left;">{{collaborator.route_name}}</span>
                <br />
                <span style="float: left;">{{collaborator.name}}</span>
              </CCol>
              <CCol :md="3"></CCol>
              <CCol :md="2">
                <strong>Usuario:</strong>
                <br />
                <strong>Contraseña:</strong>
              </CCol>
              <CCol :md="2">
                <span style="float: right;">{{user.email ? user.email : "GENERICO"}}</span>
                <br />
                <span style="float: right;">{{user.contrasena ? user.contrasena : '******'}}</span>
              </CCol>                               
            </CRow>
          </CCardBody>
        </CCard>   
      </CCol>
    </CRow>   
    <br>
    <CRow>
      <CCol :md="4" >
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Universo Quincena 1 
            </slot>
          </CCardHeader>
          <CCardBody :style="'height: '+(500 + (Math.max(metrics_1.visits.length, metrics_2.visits.length) < 10 ? Math.max(metrics_1.visits.length, metrics_2.visits.length) * 48 : 720))+'px;'">
            <CRow v-if="metrics_1.visits.length > 0">
              <CCol :md="12">
                <CChartPie
                  :datasets="defaultDatasets1"
                  :labels="graph_1[0]"
                />
              </CCol>
              <CCol :md="12" >
                <br>
                <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics_1.visits"
                  :fields="fieldsMetric"
                  :items-per-page="5"
                  pagination
                  class="table-metrics"                  
                >
                  <template #marca="{item}"><td class="text-left">{{ item.customer_name }}</td></template>
                  <template #universo="{item}"><td class="w100 text-center">{{ item.total }}</td></template>
                  <template #realizado="{item}"><td class="w100 text-center">{{ item.done }}</td></template>      
                  <template #porcentaje="{item}"><td class="w100 text-center">{{ item.rate }}</td></template>
                </CDataTable>
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-center"><strong> TOTAL</strong></td>
                        <td class="w100 text-center"><strong>{{ metrics_1.total }} </strong></td>
                        <td class="w100 text-center"><strong> {{ metrics_1.done }}</strong></td>
                        <td class="w100 text-center"></td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>         
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-left"><strong> SUELDO </strong></td>
                        <td class="w100 text-right">$ {{ metrics_1.wage | currency }}</td>
                      </tr>
                      <tr>
                        <td class="text-left"><strong>POR PAGAR </strong></td>
                        <td class="w100 text-right">$ {{ metrics_1.wage_done | currency }}</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>  
              </CCol>             
            </CRow>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTA QUINCENA</span>
          </CCardBody>
        </CCard>
      </CCol>  
      <CCol :md="4" >
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Universo Quincena 2 
            </slot>
          </CCardHeader>
          <CCardBody :style="'height: '+(500 + (Math.max(metrics_1.visits.length, metrics_2.visits.length) < 10 ? Math.max(metrics_1.visits.length, metrics_2.visits.length) * 48 : 720))+'px;'">
            <CRow v-if="metrics_2.visits.length > 0">
              <CCol :md="12">
                <CChartPie
                  :datasets="defaultDatasets2"
                  :labels="graph_2[0]"
                />
              </CCol>
              <CCol :md="12" >
                <br>
                <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics_2.visits"
                  :fields="fieldsMetric"
                  :items-per-page="5"
                  pagination
                  class="table-metrics"                  
                >
                  <template #marca="{item}"><td class="text-left">{{ item.customer_name }}</td></template>
                  <template #universo="{item}"><td class="w100 text-center">{{ item.total }}</td></template>
                  <template #realizado="{item}"><td class="w100 text-center">{{ item.done }}</td></template>      
                  <template #porcentaje="{item}"><td class="w100 text-center">{{ item.rate }}</td></template>
                </CDataTable>
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-center"><strong> TOTAL</strong></td>
                        <td class="w100 text-center"><strong>{{ metrics_2.total }} </strong></td>
                        <td class="w100 text-center"><strong> {{ metrics_2.done }}</strong></td>
                        <td class="w100 text-center"></td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>         
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-left"><strong> SUELDO </strong></td>
                        <td class="w100 text-right">$ {{ metrics_2.wage | currency }}</td>
                      </tr>
                      <tr>
                        <td class="text-left"><strong>POR PAGAR </strong></td>
                        <td class="w100 text-right">$ {{ metrics_2.wage_done | currency }}</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>  
              </CCol>             
            </CRow>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTA QUINCENA</span>
          </CCardBody>
        </CCard>
      </CCol>  
      <CCol :md="4" >
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Universo Mensual
            </slot>
          </CCardHeader>
          <CCardBody :style="'height: '+(500 + (Math.max(metrics_1.visits.length, metrics_2.visits.length) < 10 ? Math.max(metrics_1.visits.length, metrics_2.visits.length) * 48 : 720))+'px;'">
            <CRow v-if="metrics_1.visits.length > 0 || metrics_2.visits.length > 0">
              <CCol :md="12">
                <CChartPie
                  :datasets="defaultDatasets3"
                  :labels="graph_3[0]"
                />
              </CCol>
              <CCol :md="12" >
                <br>
                <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics_3.visits"
                  :fields="fieldsMetric"
                  :items-per-page="5"
                  pagination
                  class="table-metrics"                  
                >
                  <template #marca="{item}"><td class="text-left">{{ item.customer_name }}</td></template>
                  <template #universo="{item}"><td class="w100 text-center">{{ item.total }}</td></template>
                  <template #realizado="{item}"><td class="w100 text-center">{{ item.done }}</td></template>      
                  <template #porcentaje="{item}"><td class="w100 text-center">{{ item.rate }}</td></template>
                </CDataTable>
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-center"><strong> TOTAL</strong></td>
                        <td class="w100 text-center"><strong>{{ metrics_3.total }} </strong></td>
                        <td class="w100 text-center"><strong> {{ metrics_3.done }}</strong></td>
                        <td class="w100 text-center"></td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>         
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-left"><strong> SUELDO </strong></td>
                        <td class="w100 text-right">$ {{ (metrics_1.wage + metrics_2.wage) | currency }}</td>
                      </tr>
                      <tr>
                        <td class="text-left"><strong>POR PAGAR </strong></td>
                        <td class="w100 text-right">$ {{ (metrics_1.wage_done * 1 + metrics_2.wage_done * 1) | currency }}</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>  
              </CCol>             
            </CRow>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTE PERIODO</span>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol :md="6">
        <CCard >
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Visitas Quincena 1
            </slot>
          </CCardHeader>
          <CCardBody  :style="'height: '+(70 + (Math.max(visits_1.length, visits_2.length) < 15 ? Math.max(visits_1.length, visits_2.length) * 48 : 645))+'px; overflow:auto;'">
            <CDataTable
              v-if="visits_1.length > 0"
              :hover="hover"
              :striped="striped"
              :border="border"
              :small="small"
              :fixed="fixed"
              :items="visits_1"
              :fields="fields"
              :items-per-page="15"
              clickable-rows
              sorter
              pagination
              class="table-visits"
            >
              <template #tienda="{item}"><td class="text-center">{{ item.branch_name }}</td></template>
              <template #marca="{item}"><td class="w250 text-center">{{ item.customer_name }}</td></template>
              <template #fecha="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>      
              <template #status="{item}"><td class="w150 text-center">{{ (item.status == 0) ? "Pendiente": "Completada"}}</td></template>
              <template #validation="{item}">
                <td class="w150 text-center">
                  {{ item.is_validated ? item.validation_date : '-' | date }}
                  <CButton style="width: 30px; height: 30px; padding: 0px; margin-left: 10px;" v-if="item.validation_comments != '' && item.validation_comments != null" v-c-tooltip="{content: item.validation_comments}" color="secondary">
                    <CIcon name="cilCommentBubble" style="width: 20px; height: 20px;"/>             
                  </CButton>
                </td>
              </template>
              <template #actions="{item}">
                <td style="padding: 5px; border: 6px !important!" class="w100 text-center">
                  <CButton color="info" size="sm" @click="viewVisit(item)">Ver Visita</CButton>                
                </td>
              </template>
            </CDataTable>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTA QUINCENA</span>
          </CCardBody>
        </CCard>       
      </CCol>      
      <CCol :md="6">
        <CCard >
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Visitas Quincena 2
            </slot>
          </CCardHeader>
          <CCardBody  :style="'height: '+(70 + (Math.max(visits_1.length, visits_2.length) < 15 ? Math.max(visits_1.length, visits_2.length) * 48 : 645))+'px; overflow:auto;'">
            <CDataTable
              v-if="visits_2.length > 0"
              :hover="hover"
              :striped="striped"
              :border="border"
              :small="small"
              :fixed="fixed"
              :items="visits_2"
              :fields="fields"
              :items-per-page="15"
              sorter
              pagination
              class="table-visits"
            >
              <template #tienda="{item}"><td class="text-center">{{ item.branch_name }}</td></template>
              <template #marca="{item}"><td class="w250 text-center">{{ item.customer_name }}</td></template>
              <template #fecha="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>      
              <template #status="{item}"><td class="w150 text-center">{{ (item.status == 0) ? "Pendiente": "Completada"}}</td></template>
              <template #validation="{item}">
                <td class="w150 text-center">
                  {{ item.is_validated ? item.validation_date : '-' | date }}
                  <CButton style="width: 30px; height: 30px; padding: 0px; margin-left: 10px;" v-if="item.validation_comments != '' && item.validation_comments != null" v-c-tooltip="{content: item.validation_comments}" color="secondary">
                    <CIcon name="cilCommentBubble" style="width: 20px; height: 20px;"/>             
                  </CButton>
                </td>
              </template>
              <template #actions="{item}">
                <td style="padding: 5px; border: 6px !important!" class="w100 text-center">
                  <CButton color="info" size="sm" @click="viewVisit(item)">Ver Visita</CButton>                
                </td>
              </template>
            </CDataTable>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTA QUINCENA</span>
          </CCardBody>
        </CCard>       
      </CCol>  
    </CRow>
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/kam';
import moment from 'moment';
import { CChartPie } from '@coreui/vue-chartjs'



export default {
  name: 'kamRutaGenerico',
  components: {
    CChartPie
  },
  data () {
    return {      
      page: 'kamRutaGenerico',
      customer : {},
      collaborator: {       
      },
      user:{
        email: "",
        contrasena: "",
      },        
      visits_1 : [],
      visits_2 : [],
      metrics_1 : {
        visits : [],
      },
      metrics_2 : {
        visits : [],
      },
      metrics_3 : [],
      graph_1 : [],
      graph_2 : [],
      graph_3 : [],
      backgroundColors:[
        '#41B883',
        '#E46651',
        '#00D8FF',
        '#DD1B16',
        '#2F4F4F',
        '#708090',	
        '#696969',
        '#A52A2A',
        '#8B4513',
        '#DAA520',
        '#BC8F8F',
        '#000080',
        '#7B68EE',
        '#ADD8E6',
        '#40E0D0',
        '#9ACD32',
        '#90EE90',
        '#ADFF2F',
        '#800080',
        '#6A5ACD',
        '#DDA0DD',
        '#BDB76B',
        '#FFFF00',
        '#FF69B4',
        '#CD5C5C',
        '#FF5733',
      ],
      id: 0,
      hover: true,
      striped: false,
      border: true,
      small: false,
      fixed: false,
      dark: true,
      fields: [
        {key: 'tienda', label: 'Tienda'},
        {key: 'marca', label: 'Marca'},
        {key: 'fecha', label: 'Fecha Visita', },
        {key: 'status', label: 'Estado'},
      ],
      fieldsMetric: [        
        {key: 'marca', label: 'Marca'},
        {key: 'universo', label: 'Universo'},
        {key: 'realizado', label: 'Realizado'},
        {key: 'porcentaje', label: '%'},
      ],
    }
  },
  computed: {
    defaultDatasets1 () {
      return [
        {
          backgroundColor: this.backgroundColors,
          data: this.graph_1[1]
        }
      ]
    },
    defaultDatasets2 () {
      return [
        {
          backgroundColor: this.backgroundColors,
          data: this.graph_2[1]
        }
      ]
    },
    defaultDatasets3 () {
      return [
        {
          backgroundColor: this.backgroundColors,
          data: this.graph_3[1]
        }
      ]
    },
  },
  mounted: async function() {
    this.loading();
    this.id = await this.$route.params.id;
    let response_collaborator = await ws.showCollaborator(this.id);
    if(response_collaborator.type == "success"){
      this.collaborator = response_collaborator.data;
      if(response_collaborator.data.user != null){
        this.user = response_collaborator.data.user;
      }
    }

    let response_visits = await ws.collaboratorVisits(this.id);
    if(response_visits.type == "success"){
      this.visits_1 = response_visits.data.group_1;
      this.visits_2 = response_visits.data.group_2;
    }

    let response_metrics = await ws.collaboratorVisitsMetrics(this.id);
    if(response_metrics.type == "success"){
      this.metrics_1 = response_metrics.data.group_1;
      this.metrics_2 = response_metrics.data.group_2;
      this.metrics_3 = response_metrics.data.group_3;
      this.graph_1 = response_metrics.data.graph_1;
      this.graph_2 = response_metrics.data.graph_2;
      this.graph_3 = response_metrics.data.graph_3;
    }
    this.loaded();
  },
  methods: {     
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
      }
    },
    link(type, item){
      switch (type) {
        case "home":
            this.$router.push('/welcome');
          break;
      
        default:
          break;
      }      
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.table-metrics table {
  font-size: 12px !important;
}
.table-metrics table td{
  padding: 5px;
}

.table-visits table {
  font-size: 12px !important;
}
.table-visits table td{
  padding: 5px;
}
</style>
